var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c(
              "div",
              { staticClass: "mb-6", staticStyle: { "font-size": "20px" } },
              [
                _vm._v(
                  " " +
                    _vm._s(!_vm.$language ? "All Announcements" : "সকল নোটিশ") +
                    " "
                ),
              ]
            ),
            _vm.renderNow
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "max-height": "90vh",
                      "overflow-y": "auto",
                      "overflow-x": "hidden",
                    },
                  },
                  [
                    _vm.announcements === undefined ||
                    _vm.announcements.length === 0
                      ? _c("no-content-component", {
                          attrs: { message: _vm.noContentMessage },
                        })
                      : _vm._e(),
                    _vm._l(_vm.announcements, function (announcement, i) {
                      return _c(
                        "v-card",
                        {
                          key: i,
                          staticClass: "announcement-card mb-6",
                          attrs: {
                            flat: "",
                            to: {
                              name: `announcement-details-students`,
                              params: {
                                announcement_id: announcement.announcement_id,
                                course_id: announcement.course_id,
                                partner_code: announcement.partner_code,
                              },
                            },
                          },
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "pt-4 pl-8",
                              attrs: { justify: "space-between" },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "max-width": "360px" } },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-size": "16px" } },
                                    [_vm._v(_vm._s(announcement.title))]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-size": "12px",
                                        color: "#a4a4a4",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            !_vm.$language
                                              ? "Upload Date"
                                              : "প্রকাশের তারিখ"
                                          ) +
                                          " : " +
                                          _vm._s(
                                            !_vm.$language
                                              ? announcement.date_time.split(
                                                  " "
                                                )[0]
                                              : new Date(announcement.date_time)
                                                  .toLocaleString("bn-BD")
                                                  .split(" ")[0]
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "padding-right": "20px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "padding-right": "20px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {},
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              staticStyle: {
                                                "font-size": "10px",
                                                "border-radius": "5px",
                                              },
                                              attrs: { color: "#0099DC1A" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  announcement.course_title
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        _vm._l(
                                          announcement.shared_with_batches,
                                          function (batch, j) {
                                            return _c(
                                              "v-chip",
                                              {
                                                key: j,
                                                staticStyle: {
                                                  "font-size": "10px",
                                                  "margin-left": "10px",
                                                  "border-radius": "5px",
                                                },
                                                attrs: { color: "#FC62221A" },
                                              },
                                              [_vm._v(_vm._s(batch))]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "px-4",
                              staticStyle: {
                                "font-size": "12px",
                                color: "#5b5c5e",
                              },
                            },
                            [
                              _c("v-col", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    announcement.text
                                      .wrap(280)
                                      .replace(/<a.*?>.*?<\/a>/gi, "")
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              : _c(
                  "div",
                  { staticClass: "d-flex justify-center" },
                  [
                    _c("v-progress-circular", {
                      attrs: { color: "primary", indeterminate: "" },
                    }),
                  ],
                  1
                ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }