<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div style="font-size: 20px" class="mb-6">
          {{ !$language ? "All Announcements" : "সকল নোটিশ" }}
        </div>
        <div
          v-if="renderNow"
          style="max-height: 90vh; overflow-y: auto; overflow-x: hidden"
        >
        <no-content-component v-if="announcements === undefined || announcements.length === 0"
        :message="noContentMessage"
        >
        </no-content-component>
          <v-card
            v-for="(announcement, i) in announcements"
            :key="i"
            flat
            class="announcement-card mb-6"
            :to="{
              name: `announcement-details-students`,
              params: {
                announcement_id: announcement.announcement_id,
                course_id: announcement.course_id,
                partner_code: announcement.partner_code,
              },
            }"
          >
            <v-row class="pt-4 pl-8" justify="space-between">
              <div style="max-width: 360px">
                <div style="font-size: 16px">{{ announcement.title }}</div>
                <div style="font-size: 12px; color: #a4a4a4">
                  {{ !$language ? "Upload Date" : "প্রকাশের তারিখ" }} :
                  {{
                    !$language
                      ? announcement.date_time.split(" ")[0]
                      : new Date(announcement.date_time)
                          .toLocaleString("bn-BD")
                          .split(" ")[0]
                  }}
                </div>
              </div>
              <div style="display: flex; padding-right: 20px">
                <!--                <div style="font-size: 13px;" class="mt-1 mr-5"><v-icon size="18" color="#0099DC">share</v-icon></div>-->
                <div style="display: flex; padding-right: 20px">
                  <div style="">
                    <v-chip
                      style="font-size: 10px; border-radius: 5px"
                      color="#0099DC1A"
                      >{{ announcement.course_title }}</v-chip
                    >
                  </div>
                  <div>
                    <v-chip
                      v-for="(batch, j) in announcement.shared_with_batches"
                      :key="j"
                      style="
                        font-size: 10px;
                        margin-left: 10px;
                        border-radius: 5px;
                      "
                      color="#FC62221A"
                      >{{ batch }}</v-chip
                    >
                  </div>
                </div>
              </div>
            </v-row>
            <v-row style="font-size: 12px; color: #5b5c5e" class="px-4">
              <v-col
                v-html="
                  announcement.text.wrap(280).replace(/<a.*?>.*?<\/a>/gi, '')
                "
              />
            </v-row>
          </v-card>
        </div>
        <div v-else class="d-flex justify-center">
          <v-progress-circular
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import announcementService from "#ef/announcement/services/AnnouncementService";
import { Logger } from "/global/utils/helpers";
import { errorFormat } from "/global/utils/helpers";
import { mapState, mapGetters } from "vuex";
import NoContentComponent from "/global/components/NoContentComponent";

export default {
  name: "Announcements",
  components: {NoContentComponent},
  data() {
    return {
      partner_code_for_course_id: {},
      shareDateMenu: false,
      announcements: [],
      last_fetch_time: this.$store.state.studentAnnouncement.last_fetch_time,
      renderNow: false,
    };
  },
  computed: {
    ...mapGetters({ announcementsNew: "announcement/allAnnouncements" }),
    organizations() {
      return this.$store.state.user.user.organizations;
    },
    noContentMessage(){
      return !this.$language ? "There are no announcement" : "কোনো অ্যানাউন্সমেন্ট নেই";
    },
    courses() {
      return this.$store.getters["course/courses"];
    },
    batches() {
      return this.$store.getters["batch/batches"];
    },
  },
  async created() {
    const isOnline = window.navigator.onLine;
    if ( !isOnline ) {
      this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"]);
      return;
    }
    await this.getAnnouncements();
  },
  methods: {
    async getAnnouncements() {
      try {
        let content = await announcementService.list(this.last_fetch_time);
        if (content.updated === true && content.announcements !== undefined) {
          this.announcements = content.announcements;
          this.last_fetch_time = content.last_fetch_time;
          await this.$store.dispatch("announcement/setAnnouncements", {
            announcements: this.announcements,
            last_fetch_time: this.last_fetch_time,
          });
        } else this.announcements = await this.announcementsNew;
        this.renderNow = true;
      } catch (e) {
        Logger.log(e);
        this.$root.$emit("alert", [
          undefined,
          e.message,
        ]);
      }
    },
  },
};
</script>

<style>
.v-input .v-label {
  font-size: 12px !important;
}
</style>

<style scoped>
.course-label {
  padding-top: 6px;
  height: 30px;
  text-align: center;

  background: #0099dc1a 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #0000001a;
  border-radius: 5px;
  opacity: 1;
}

.batch-label {
  padding-top: 6px;
  height: 30px;
  text-align: center;
  justify-content: center;
  background: #fc62221a 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #0000001a;
  border-radius: 5px;
  opacity: 1;
}

</style>
